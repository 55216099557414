@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap);
body {
    margin: 0;
    color: #334155;
    font-family: 'Lato', sans-serif;
    font-size: 1em;
    line-height: 1.5;
    font-weight: 300;
    padding: 2rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Minter {
  /*max-width: 80vh;*/
  /*max-height: 80vh;*/
  /*padding: 60px 100px;*/
  margin: 0 auto;
  text-align: left;

}

h1 {

}

input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  font-size: 16px;
  width: 100%;
  border-color: rgba(4, 4, 5, 0.1);
  line-height: 32px;
}

input:focus {
  outline: none;
  border-color: rgba(4, 4, 5, 0.4);
}

button {
  /*padding: 9px 16px;*/
  /*max-height: 40px;*/
  /*border-color: #254cdd;*/
  /*color: #254cdd;*/
  /*background-color: white;*/
  /*border-radius: 8px;*/
  /*align-items: center;*/
  /*font-size: 16px;*/
  /*font-weight: 500;*/
  /*text-align: center;*/
  /*font-weight: bold;*/
  cursor:pointer;

    background-color: #1E293B;
    color: #F1F5F9;
    display: inline-flex;
    /*width: 100vw;*/
    height: 2.5rem;
    line-height: 2.5rem;
    padding: 0 1.25rem;
    vertical-align: middle;
    font-family: 'Titillium Web', sans-serif;
    font-size: 1em;
    font-weight: 400;
    /*border-radius: 2rem;*/
    transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
}

#title {
  padding-top: 32px;
}

#status {
  color:red;
}

.uploadFile {
  border: 4px dashed rgba(4, 4, 5, 0.1);
  border-radius: 16px;
  text-align: center;
  padding: 32px 60px;
}

.tdHeader {
    padding-right: 2em;
}

#walletButton {
  /*text-align: right;*/
  /*float:right;*/
  /*margin-bottom: 50px;*/
  /*line-height: 16px;*/
}

#mintButton {
  /*margin-top: 40px;*/
  /*background-color: #254cdd;*/
  /*color:white;*/

}

.mintBtn {
    margin-top: 1em;
    display: block;
    cursor: pointer;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

